import React from "react";
import { navigate } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./css/new.css";


class New extends React.Component {
    constructor(props) {
        super(props);
        this.state = { id: "" };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value })
    }


    handleSubmit = async event => {
        event.preventDefault();

        const baseUrl = "https://serverless-api.suvan.workers.dev/";

        // // send request to /api/user/:id route
        // const getUser = await fetch(`${baseUrl}/api/user/${this.state.username}`);
        // const userText = await getUser.text();

        // // if username already exists
        // if (userText !== "Not found") {
        //     const userJson = await JSON.parse(userText);

        //     // Show error message
        //     const errMsg = document.getElementsByClassName("user-taken-msg")[0];
        //     errMsg.innerHTML = `An error occurred: username '${userJson.username}' is taken`;
        //     return errMsg.classList.add("visible");

        //     // return console.log(`user found: ${userJson.username}`);
        // }

        // Send get request for existing data
        const url = `${baseUrl}/api/posts`;
        const get = await fetch(url);
        const getJson = await get.json();

        // Get current highest post ID (acc = accumulator)
        const highestId = getJson.reduce((acc, data) => acc = acc > data.id ? acc : data.id, 0);
    
        // Define state for post request
        const state = {
            id: highestId + 1,
            timestamp: new Date(),
            upvotes: 0,
            downvotes: 0,
            reactions: [{
                funny: 0,
                happy: 0,
                angry: 0,
                cool: 0
            }],
            comments: []
        }

        // Set state for post request
        this.setState(state);

        // Send post request
        const res = await fetch(url, { method: "POST", body: JSON.stringify(this.state) });

        if (res.status === 201) {
            // Redirect home
            return navigate("/");
        } else {
            // Show error message
            const errMsg = document.getElementsByClassName("error-msg")[0];
            errMsg.classList.add("visible");
        }
    }


    render = () => {
        return (
            <div className="new-post-container">
                <div className="new-header posts-header">
                    <h1>
                        <a href="/" className="btn-back"><FontAwesomeIcon icon="arrow-left" /></a>
                        🌟 New post
                    </h1>
                </div>

                <div className="error-msg">
                   <span><FontAwesomeIcon icon="exclamation-circle" /> An error occurred</span>
                </div>

                <div className="user-taken-msg error-msg">
                   <span><FontAwesomeIcon icon="exclamation-circle" /></span>
                </div>
    
                <div className="new-post-form-container">
                    <form action="/" onSubmit={this.handleSubmit} className="new-post-form">
                        <label>
                            Username
                            <br />
                            <input onChange={this.handleChange} type="text" id="username" name="username" />
                        </label>
                        <br />
    
                        <label>
                            Post title
                            <br />
                            <input onChange={this.handleChange} type="text" id="title" name="title" />
                        </label>
                        <br />
    
                        <label>
                            Post content
                            <br />
                            <textarea onChange={this.handleChange} id="content" name="content" rows="4" cols="50"></textarea>
                        </label>
                        <br />
    
                        <input className="btn-submit" type="submit" value="Submit" />
                    </form>
                </div>

                <div className="new-hint-msg">
                    <span>
                        <FontAwesomeIcon icon="info-circle" /> Please be patient once you've submitted your post.
                        It may take up to a minute for the posts cache to update and display your post on the homepage.
                        You'll need to refresh the page to see your new post appear.
                    </span>
                </div>
            </div>
        );
    }
}

export default New;