import React, { useEffect, useState } from "react";
import { Link, navigate } from "@reach/router";
import { getLink, stripEndUrl } from "./posts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./css/posts.css";
import "./css/post.css";

const Post = ({ id }) => {
    const [post, setPost] = useState({});
    const [comment, setComment] = useState({});
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        const getPost = async () => {
            const resp = await fetch(`https://serverless-api.suvan.workers.dev/api/posts/${id}`);
            const postResp = await resp.json();
            setPost(postResp);
        };

        const refresh = async () => {
            if (submit) await navigate(`/posts/${id}`);
        }
        
        getPost();

        refresh();
    }, [id, submit]);

    if (!Object.keys(post).length) return <div />;


    const handleChange = event => {
        setComment({ ...comment, [event.target.name]: event.target.value, upvotes: 0, downvotes: 0, timestamp: new Date() })
    }


    const handleSubmit = async event => {
        event.preventDefault();

        // send request to /api/user/:id route
        const getUser = await fetch(`https://serverless-api.suvan.workers.dev/api/user/${comment.user}`);
        const userText = await getUser.text();

        // if username already exists
        if (userText !== "Not found") {
            const userJson = await JSON.parse(userText);

            // Show error message
            const errMsg = document.getElementsByClassName("user-taken-msg")[0];
            errMsg.innerHTML = `An error occurred: username '${userJson.username}' is taken`;
            return errMsg.classList.add("visible");

            // return console.log(`user found: ${userJson.username}`);
        }

        const url = `https://serverless-api.suvan.workers.dev/api/posts/${id}`;
        
        try {
            const res = await fetch(url, { method: "PUT", body: JSON.stringify(comment) });
            
            if (res.status === 200) {
                // Show success message
                const successMsg = document.getElementById("comment-success");
                successMsg.classList.add("visible");
                
                // rerenders components on page
                setSubmit(true);
            } else {
                // Show error message
                const errMsg = document.getElementsByClassName("error-msg")[0];
                errMsg.classList.add("visible");
            }
        } catch (err) {
            // Show error message
            const errMsg = document.getElementsByClassName("error-msg")[0];
            errMsg.classList.add("visible");

            console.error(`An error occurred in PUT:\n${err}`);
        }
    }


    return (
        <div className="post-page-container">
            <div className="posts-header">
                <h1>
                    <a href="/" className="btn-back"><FontAwesomeIcon icon="arrow-left" /></a>
                    📮 Post
                </h1>
                <Link to="/new" className="btn-new-post alignright"><FontAwesomeIcon icon="pen-alt" /> New post</Link>
            </div>

            <div className="post-container">
                <h2>{post.title}</h2>

                <p>{stripEndUrl(post.content)}</p>

                <p>
                    <sup>{new Date(post.timestamp).toLocaleString()}</sup>
                </p>

                {getLink(post)}

                <div className="post-footer">
                    <p className="alignleft">
                        <sup>
                            <span className="post-username">@{post.username}</span>
                            <span className="sep"> • </span>
                            <span className="post-date">{new Date(post.timestamp).toLocaleString()}</span>
                            <span className="sep"> • </span>
                            <Link to="/" className="view-full">Go back</Link>
                        </sup>
                    </p>

                    <p className="votes alignright">
                        <span className="sep">  </span>
                        <FontAwesomeIcon className="post-footer-fa" icon="arrow-up" /> {post.upvotes}
                        <FontAwesomeIcon className="post-footer-fa" icon="arrow-down" /> {post.downvotes}
                    </p>

                    <p className="reactions alignright">
                        <button className="btn-reaction">😂</button> {post.reactions[0].funny} <span className="sep"> • </span>
                        <button className="btn-reaction">😀</button> {post.reactions[0].happy} <span className="sep"> • </span>
                        <button className="btn-reaction">😡</button> {post.reactions[0].angry} <span className="sep"> • </span>
                        <button className="btn-reaction">😎</button> {post.reactions[0].cool}
                    </p>
                </div>
            </div>

            <div className="comments-container">
                <h2 className="comments-header">💬 Comments - {post.comments.length}</h2>

                <div className="error-msg">
                   <span><FontAwesomeIcon icon="exclamation-circle" /> An error occurred</span>
                </div>

                <div className="user-taken-msg error-msg">
                   <span><FontAwesomeIcon icon="exclamation-circle" /></span>
                </div>

                <div className="new-comment-container">
                    <span className="new-comment-hint"><FontAwesomeIcon icon="comments" /> Post a new comment</span>
                    <div className="comment-form">
                        <form action="/" onSubmit={handleSubmit}>
                            <input onChange={handleChange} className="comment-input" type="text" id="user" name="user" placeholder="Your username" />
                            <input onChange={handleChange} className="comment-input" type="text" id="content" name="content" placeholder="Your comment" />
                            <input className="comment-btn-submit" type="submit" value="Submit" />
                        </form>
                    </div>
                </div>

                <div className="new-hint-msg comment-success" id="comment-success">
                    <span>
                        <FontAwesomeIcon icon="info-circle" /> <strong>Your comment has been submitted!</strong>
                    </span>
                </div>

                {[...post.comments].reverse().map((comment) => (
                    <div className="comments-area">
                        <p className="comment-user">@{comment.user}</p>
                        <p className="comment-content">{stripEndUrl(comment.content)}</p>
                        <p className="embedded-media">{getLink(comment)}</p>
                        
                        <div className="comments-footer">
                            <p>
                                <span className="comment-time">{new Date(comment.timestamp).toLocaleString()}</span>
                                <span className="sep"> • </span>
                                <span className="comment-votes">
                                    <FontAwesomeIcon className="comment-footer-fa" icon="arrow-up" /> {comment.upvotes}
                                    <FontAwesomeIcon className="comment-footer-fa" icon="arrow-down" /> {comment.downvotes}
                                </span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Post;