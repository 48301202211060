import { Router } from "@reach/router";

import Posts from "./components/posts";
import Post from "./components/post";
import New from "./components/new";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import "./App.css";

library.add(fas);

function App() {
    return (
        <Router>
            <Posts path="/"/>
            <Post path="/posts/:id" />
            <New path="/new" />
        </Router>
    );
}

export default App;
