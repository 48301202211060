import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./css/posts.css";


// Handles embedding of links/images
export const getLink = post => {
    // Find first link in post content
    const urlRe = new RegExp(/.[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    const urlMatch = post.content.match(urlRe) || [];

    // if at least 1 url is found
    if (urlMatch.length >= 1) {
        // if the url is an image link
        const imgUrlRe = new RegExp(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/g);
        const imgMatch = urlMatch[0].match(imgUrlRe) || [];
        // embed the image
        if (imgMatch.length >= 1) return (
            <img alt="Embedded media" src={imgMatch[0].replace(/ /g, "")} />
        );

        // if the url is a regular link, create a link embed
        else return (
            <div className="link-embed-container">
                <a className="link-embed" target="_blank" rel="noreferrer" href={urlMatch[0].replace(/ /g, "")}>
                    <span className="link-hint"><FontAwesomeIcon icon="link" /> Link</span>
                    <br />
                    <span className="link-url">{urlMatch[0].replace(/ /g, "")}</span>
                </a>
            </div>
        );
    }
}


// Function to remove the URL when a post ends with a URL
export const stripEndUrl = content => {
    const urlRe = new RegExp(/.[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
    const urlMatch = content.match(urlRe) || [];
    if (urlMatch.length >= 1) {
        if (content.endsWith(urlMatch[urlMatch.length - 1])) return content.replace(urlMatch[urlMatch.length - 1], "");
        else return content;
    } else {
        return content;
    }
}


const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch("https://serverless-api.suvan.workers.dev/api/posts");
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);


    return (
        <div className="posts-container">
            <div className="posts-header">
                <h1>📃 Feed</h1>
                <Link to="/new" className="btn-new-post alignright"><FontAwesomeIcon icon="pen-alt" /> New post</Link>
            </div>

            <div className="posts-subheader">
                <h3>Sorted by <strong>latest</strong></h3>
            </div>

            <div className="posts-hint">
                <span><FontAwesomeIcon className="tip-icon" icon="info-circle" /> Want to comment on a post? Viewing the full post will allow you to do so.</span>
            </div>

            <hr className="horizontal-sep" />

            {[...posts].reverse().map((post) => (
                <div className="post-container" key={post.id}>
                    <h2>
                        <Link to={`/posts/${post.id}`} className="post-link">{post.title}</Link>
                    </h2>

                    <p>
                        {stripEndUrl(post.content)}
                    </p>

                    {getLink(post)}

                    <div className="post-footer">
                        <p className="alignleft">
                            <sup>
                                <span className="post-username">@{post.username}</span>
                                <span className="sep"> • </span>
                                <span className="post-date">{new Date(post.timestamp).toLocaleString()}</span>
                                <span className="sep"> • </span>
                                <Link to={`/posts/${post.id}`} className="view-full">View full post</Link>
                            </sup>
                        </p>

                        <p className="votes alignright">
                            <span className="sep"> | </span>
                            <FontAwesomeIcon className="post-footer-fa" icon="arrow-up" /> {post.upvotes}
                            <FontAwesomeIcon className="post-footer-fa" icon="arrow-down" /> {post.downvotes}
                        </p>

                        <p className="comments alignright">
                            <span className="sep"> | </span>
                            <FontAwesomeIcon className="post-footer-fa" icon="comment" /> {post.comments.length}
                        </p>

                        <p className="reactions alignright">
                            <button className="btn-reaction">😂</button> {post.reactions[0].funny} <span className="sep"> • </span>
                            <button className="btn-reaction">😀</button> {post.reactions[0].happy} <span className="sep"> • </span>
                            <button className="btn-reaction">😡</button> {post.reactions[0].angry} <span className="sep"> • </span>
                            <button className="btn-reaction">😎</button> {post.reactions[0].cool}
                        </p>
                    </div>
                </div>
            ))}
      </div>
    );
};

export default Posts;